import React from 'react';
import { mergeClassNames } from '../utils';
import * as shapes from './proptypes';
import RichText from './RichText';
import styles from './Card.module.css';
import TextLink from './TextLink';

const Card = (props) => {
  const { content, config, className, callToAction } = props;
  return (
    <section className={mergeClassNames(className, styles.card)}>
      <RichText config={config} json={content && content.json} />
      {callToAction && (
        <TextLink href={callToAction.url}>{callToAction.label}</TextLink>
      )}
    </section>
  );
};

Card.propTypes = {
  content: shapes.richTextJson,
  config: shapes.config,
};

Card.defaultProps = {};

export default Card;
