import React from 'react';
import { mergeClassNames } from '../utils';
import styles from './NotificationCard.module.css';
import Translate from './Translate';

export default function NotificationCard(props) {
  return <Translate
    {...props}
    className={mergeClassNames(styles.root, props.className)}
  />
}