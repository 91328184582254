import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  withScriptjs,
} from 'react-google-maps';
import { isWindowSafe } from '../utils';

const googleMapsApi =
  'https://maps.google.com/maps/api/js?key=AIzaSyBm7B2X7cFYxkMMipm_HDSL4Iugy5NcSWU';

const GoogleMapWrapper = ({ lat, lng, address, markerIcon, ...props }) => {
  const [location, setLocation] = useState({
    lat: +lat,
    lng: +lng,
  });

  const onClick = () => {
    if (isWindowSafe()) {
      const { lat, lng } = location;
      const win = window.open(
        `https://google.com/maps?q=${lat},${lng}`,
        '_blank'
      );
      win.focus();
    }
  };

  const setPosition = () => {
    const { lat, lng } = location;
    if (lat && lng) return;
    if (isWindowSafe() && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          address,
        },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const [{ geometry }] = results;
            setLocation({
              lat: +geometry?.location.lat(),
              lng: +geometry?.location.lng(),
            });
          } else {
            console.error({
              results,
              status,
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    setPosition();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const markerSize = 70;

  if (!location.lng || !location.lat) return null;

  return (
    <GoogleMap
      onClick={onClick}
      options={{
        scrollwheel: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        overviewMapControl: false,
        rotateControl: false,
        panControl: false,
        fullscreenControl: false,
      }}
      defaultZoom={14}
      defaultCenter={location}
      {...props}
    >
      <Marker
        icon={
          markerIcon
            ? {
                url: markerIcon,
                size: {
                  width: markerSize,
                  height: markerSize,
                },
                scaledSize: {
                  width: markerSize,
                  height: markerSize,
                },
                anchor: {
                  x: markerSize / 2,
                  y: markerSize / 2,
                },
              }
            : undefined
        }
        position={location}
        onClick={onClick}
      />
    </GoogleMap>
  );
};

GoogleMapWrapper.propTypes = {
  address: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

const GoogleMapWrapped = withScriptjs(withGoogleMap(GoogleMapWrapper));

export default (props) => (
  <GoogleMapWrapped
    googleMapURL={googleMapsApi}
    loadingElement={<div style={{ width: '100%', height: '100%' }} />}
    containerElement={
      <div style={{ width: '100%', height: '100%', marginBottom: 20 }} />
    }
    mapElement={
      <div style={{ width: '100%', height: '100%', minHeight: '200px' }} />
    }
    {...props}
  />
);
