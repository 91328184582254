import React from 'react';
import { translate } from '../utils';
import RichText from './RichText';

const Translate = ({ resources, translate: trans, returnKey, ...props }) => {
  const result = translate(resources, trans, returnKey);
  return <RichText {...props} json={result?.json} />;
};

export default Translate;
