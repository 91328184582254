import React from 'react';
import { mergeClassNames } from '../utils';
import styles from './TextLink.module.css';
import { Link } from 'gatsby';
import Icon from './Icon';

const TextLink = ({ className, href, children, light }) => {
  const classNames = mergeClassNames(styles.root, className);
  if (href.match(/http[s]?:\/\//)) {
    return (
      <a className={classNames} href={href}>
        {children}
      </a>
    );
  }

  return (
    <Link className={classNames} to={href} state={{ internal: true }}>
      <div>
        <Icon icon="arrow" />
        <span>{children}</span>
      </div>
    </Link>
  );
};

export default TextLink;
