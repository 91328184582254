import { Link } from 'gatsby';
import React, { forwardRef } from 'react';
import { formatArea, formatCurrency, initTranslate, mergeClassNames, parseAddress, parseMarketingType, slugify } from '../utils';
import styles from './EstatePreview.module.css';
import Icon from './Icon';
import SkeletonImage from './SkeletonImage';

const EstatePreview = forwardRef((props, ref) => {
  const {
    id,
    headline,
    identifier,
    livingarea,
    rooms,
    _metadata,
    basePath,
    resources,
    addresses,
    rent,
    purchaseprice,
    mainImage,
    config
  } = props;


  const translate = initTranslate(resources, true);

  const naText = translate('estates.n-a');
  const onRequestText = translate('estates.onrequest');

  const price = rent || purchaseprice;

  const marketingType = parseMarketingType(_metadata);
  const address = parseAddress(addresses);

  const detailPath = `${basePath}/${slugify(headline)}?id=${identifier}`.replace(/\/\//g, '/');
  const pillTitle = `${translate('estates.identifier')} ${identifier}`;
  const locale = config?.localization?.locale?.code || 'de';

  return (
    <article ref={ref} data-estateid={id} className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <div className={mergeClassNames(styles.identifier, styles.pill)} title={pillTitle}>
            {pillTitle}
          </div>
          <Link to={detailPath} state={{ internal: true }}>
            <SkeletonImage className={styles.image} src={mainImage?.uri} alt={headline} />
          </Link>
          <Icon
            className={styles.loader}
            icon="loader"
          />
        </div>
        <div className={styles.cardContent}>
          <h5>{headline}</h5>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className={styles.pill}>
                    {translate('estates.location')}
                  </div>
                </td>
                <td>
                  {address || onRequestText}
                </td>
              </tr>
              {rooms && (
                <tr>
                  <td>
                    <div className={styles.pill}>
                      {translate('estates.rooms')}
                    </div>
                  </td>
                  <td>{rooms || naText}</td>
                </tr>
              )}
              {!!livingarea && (
                <tr>
                  <td>
                    <div className={styles.pill}>
                      {translate('estates.area')}
                    </div>
                  </td>
                  <td>{formatArea(livingarea, locale) || naText}</td>
                </tr>
              )}
              <tr>
                <td>
                  <div className={styles.pill}>
                    {translate(`estates.${marketingType?.toLowerCase()}`)}
                  </div>
                </td>
                <td>{price && price != 1 ? formatCurrency(price, locale) : onRequestText}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Link to={detailPath} className={styles.link} state={{ id }}>
        {translate('estates.to-estate-detail')}
      </Link>
    </article>
  );
});

export default EstatePreview;
