import React from 'react';
import { mergeClassNames } from '../utils';
import styles from './Button.module.css';

const Button = ({ className, onClick, label, children, ...props }) => {
  return (
    <button
      className={mergeClassNames(styles.root, className)}
      onClick={onClick}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
};

export default Button;
