import uniqBy from 'lodash.uniqby';
import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import SmoothScroll from 'smooth-scroll';
import { formatArea, formatCurrency, isWindowSafe, mergeClassNames, parseAddress, parseMarketingType, slugify, useEstates, useTranslate } from '../utils';
import Button from './Button';
import CustomSlider from './CustomSlider';
import styles from './EstateDetail.module.css';
import EstatePrint from './EstatePrint';
import GoogleMap from './GoogleMap';
import NotificationCard from './NotificationCard';

const split = (array) => {
  if (!array.length) return [];
  const half = Math.floor(array.length / 2);
  return [array.slice(0, half), array.slice(half, array.length)];
};

const EstateDetail = (props) => {
  const { className, resources, config } = props;
  const locale = config?.localization?.locale?.code || 'de';
  const isProduction = config?.env === 'production';

  const { res, isLoading } = useEstates({
    id: encodeURIComponent(props.pageContext.estateIdentifier) || props.pageContext.estateId,
  }, locale, isProduction);

  const translate = useTranslate([
    ...resources,
    ...Object.entries(res._translations || {}).map(([key, value]) => ({ key, value }))
  ]);

  const estate = res?.entry;
  const title = estate?.headline;
  useEffect(() => {
    const textArea = document.querySelector('form textarea');
    if (title && textArea) {
      textArea.value = `${translate('form.expose-inquiry')} "${title}"`;
    }
  }, [title]);

  if (isLoading) {
    return (
      <div className={mergeClassNames(className, styles.root, styles.loader)}>
        <h1>
          <Skeleton />
        </h1>
        <div className={styles.mainSlider}>
          <Skeleton height="50vh" />
          <div>
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
            <Skeleton height="150px" width="180px" />
          </div>
        </div>
        <div>
          <Skeleton height="1.5rem" count={5} />
          <Skeleton height="1.5rem" count={3} />
        </div>
      </div>
    )
  }

  if (!estate) {
    return <NotificationCard
      className={styles.card}
      config={config}
      resources={resources}
      translate={'estates.notfound-state'}
    />;
  }

  const {
    headline: _h,
    id: _i,
    mainImage,
    groundplotImage = [],
    onlineImage = [],
    addresses,
    freeFormTexts,
    estatetype,
    purchaseprice,
    rent,
    livingarea,
    rooms,
    _metadata,
    textEstate,
    textLocation,
    textEnvironment,
    textFree,
    ...rest
  } = estate;


  const slug = slugify(title);

  const attachments = uniqBy([mainImage, ...onlineImage, ...groundplotImage], 'uri').filter(attachment => !!attachment?.uri).map(attachment => ({
    ...attachment,
    uri: attachment.uri.replace('https://s3.eu-central-1.amazonaws.com/cloudios.production.image', '/api/images')
  }));

  const address = parseAddress(addresses);
  const marketingType = parseMarketingType(_metadata);

  const specifications = Object.entries(rest).map(([key, value]) => ({ key, value }));

  const price = rent || purchaseprice;

  const specificationSets = split([
    ...[
      {
        key: translate('estates.location'),
        value: address,
      },
      {
        key: translate('estates.estatetype'),
        value: estatetype,
      },
      {
        key: translate('estates.marketingtype'),
        value: translate(`filter.${marketingType}`),
      },
    ].filter(({ value }) => !!value),
    ...[
      {
        key: `estates.${marketingType}`,
        value: formatCurrency(price, locale) || translate('estates.onrequest'),
      },
      {
        key: 'estates.area',
        value: livingarea
      },
      {
        key: 'estates.rooms',
        value: rooms,
      },
      ...(specifications || []),
    ]
      .filter(({ value }) => !!value)
      .map(({ key, value }) => ({
        key: translate(key),
        value: key.includes('area') ?
          formatArea(value, locale) :
          key.includes('price') || key.includes('rent') || key.includes('expenses') ?
            formatCurrency(value, locale) :
            value,
      }))
      .sort((a, b) => {
        // sort first identifier, then €, then m², then numbers
        if (a.key === translate('identifier')) return -1;
        if (b.key === translate('identifier')) return 1;
        if (a.value?.includes?.('€')) return -1;
        if (b.value?.includes?.('€')) return 1;
        if (a.value?.includes?.('m²')) return -1;
        if (b.value?.includes?.('m²')) return 1;
        if (a.value?.match?.(/\d+/) || !isNaN(a.value)) return -1;
        if (b.value?.match?.(/\d+/) || !isNaN(b.value)) return 1;
        return 0;
      }),
  ]);

  const sanitizedFreeFormTexts = [
    { key: translate('textestate'), value: textEstate },
    { key: translate('textlocation'), value: textLocation },
    { key: translate('textenvironment'), value: textEnvironment },
    { key: translate('textfree'), value: textFree },
  ].filter(({ value }) => !!value);

  const printData = {
    title,
    translate,
    specifications: {
      header: translate('estates.object-data'),
      sets: specificationSets,
    },
    texts: sanitizedFreeFormTexts,
    attachments,
  };

  const specs = specificationSets.map((set, i) => (
    <table key={`table-${i}`}>
      <tbody>
        {set.map(({ key, value }) => {
          return (
            <tr key={key}>
              <td title={key}>{key}</td>
              <td title={value}>
                <article>
                  <p>{value}</p>
                </article>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ));

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={mergeClassNames(className, styles.root)}>
        <h1>
          {title}
        </h1>
        <section>
          <CustomSlider
            isMain
            className={styles.slider}
            attachments={attachments}
          />
          <div className={styles.details}>
            {!!specs?.length && (
              <Fragment>
                <h3>{translate('estates.object-data')}</h3>
                <div className={styles.tableWrapper}>{specs}</div>
              </Fragment>
            )}
            {sanitizedFreeFormTexts.map(({ key, value }) => {
              if (!value) return null;
              return (
                <div key={key}>
                  <h3>{key}</h3>
                  <div className={styles.text}>
                    <article>
                      <p>{value}</p>
                    </article>
                  </div>
                </div>
              );
            })}
            <Button
              className={styles.button}
              url={'#contact-form'}
              onClick={() => {
                isWindowSafe() &&
                  new SmoothScroll().animateScroll(
                    document.querySelector('#contact-form'),
                    undefined,
                    { offset: 100 }
                  );
              }}
            >
              {translate('estates.send-inquiry')}
            </Button>
          </div>
        </section>
        {address && (
          <div className={styles.map}>
            <GoogleMap
              address={address}
              showCircle
            />
          </div>
        )}
        <EstatePrint
          data={printData}
          id={slug}
          locale={locale}
        />
      </div>
    </Fragment >
  );
};

EstateDetail.propTypes = {};

export default EstateDetail;
