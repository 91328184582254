import React from 'react';

import './Hamburger.css';
import { mergeClassNames } from '../utils';

const Hamburger = ({ className, onClick, isActive }) => {
  return (
    <button
      className={mergeClassNames(className, 'hamburger', 'hamburger--spring', {
        'is-active': isActive,
      })}
      onClick={onClick}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};

export default Hamburger;
