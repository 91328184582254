import React from 'react';
import Slider from 'react-slick';
import { isPhone, isWindowSafe } from '../utils';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import styles from './References.module.css';
import Translate from './Translate';

const getSlidesToShow = () => {
  if (isWindowSafe()) {
    if (window.innerWidth < 480) {
      return 1;
    }
  }
  return 3;
};

const sliderProps = {
  infinite: true,
  pauseOnHover: false,
  speed: 300,
  fade: false,
  autoplay: true,
  centerMode: true,
  slidesToScroll: 1,
  slidesToShow: getSlidesToShow(),
  lazyLoad: true,
  dots: !isPhone(),
};

const References = ({ references, resources, config }) => {
  if (!references) return null;
  return (
    <section className={styles.root}>
      <Translate
        resources={resources}
        config={config}
        translate="references.header"
      />
      <Slider {...sliderProps} className={styles.slider}>
        {[...references, ...references].map(({ id, title, image }, i) => {
          return (
            <div key={`${id}_${i}`} className={styles.slide}>
              <PreviewCompatibleImage
                className={styles.image}
                image={image?.squared}
                alt={title}
              />
              <span className={styles.title}>
                <h5>{title}</h5>
              </span>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default References;
