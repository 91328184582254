import React, { useRef } from 'react';
import Slider from 'react-slick';
import { isPhone, mergeClassNames } from '../utils';

import styles from './CustomSlider.module.css';
import SkeletonImage from './SkeletonImage';

const mainProps = {
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  arrows: false,
  dots: isPhone(),
  autoplay: false,
};


const previewProps = {
  ...mainProps,
  fade: false,
  centerMode: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: false,
  arrows: true,
  variableWidth: true,
};

const singleImage = {
  infinite: false,
  autoplay: false,
};

const CustomSlider = ({ attachments, className }) => {
  const main = useRef(null);
  const preview = useRef(null);

  if (!attachments.length) return null;

  const goToSlide = (i) => {
    preview?.current && preview.current.slickGoTo(i);
    main?.current && main.current.slickGoTo(i);
  };

  const mapItems = (attachments, isMain) => {
    return attachments.map((attachment, i) => {
      const {
        fileName,
        headline: title,
        uri,
      } = attachment;

      const goTo = () => !isMain && goToSlide(i);
      const key = `${uri}_${isMain ? 'main' : 'preview'}_${i}`;
      if (fileName?.toLowerCase().includes('.tif')) return null;
      if (fileName?.toLowerCase().includes('.pdf')) {
        return (
          <a
            key={key}
            target="_blank"
            rel="noopener noreferrer"
            href={uri}
            onClick={goTo}
          >
            <div className={styles.slide}>
              <object data={uri} type="application/pdf">
                <embed src={uri} type="application/pdf"></embed>
              </object>
              <p>{fileName}</p>
            </div>
          </a>
        );
      }
      return (
        <div key={key} className={styles.slide} onClick={goTo}>
          {isMain && (
            <SkeletonImage
              className={mergeClassNames(styles.image, styles.backgroundFill)}
              src={uri}
              alt={title}
            />
          )}
          <SkeletonImage
            className={styles.image}
            skeletonClassName={isMain ? styles.skeleton : undefined}
            src={uri}
            alt={title}
          />
          <p className={styles.imageTitle}>{title}</p>
        </div>
      );
    });
  };

  const hasOneImage = attachments.length <= 1;
  const dynamicProps = hasOneImage ? singleImage : {};

  return (
    <div
      className={mergeClassNames(className, styles.root, {
        [styles.oneImage]: hasOneImage,
      })}
    >
      <Slider
        {...mainProps}
        {...dynamicProps}
        className={styles.main}
        asNavFor={preview?.current}
        ref={main}
      >
        {mapItems(attachments, true)}
      </Slider>
      {!hasOneImage && !isPhone() && (
        <Slider
          {...previewProps}
          {...dynamicProps}
          className={styles.preview}
          asNavFor={main?.current}
          ref={preview}
        >
          {mapItems(attachments)}
        </Slider>
      )}
    </div>
  );
};

export default CustomSlider;
