import React from 'react';
import { mergeClassNames } from '../utils';
import * as shapes from './proptypes';
import RichText from './RichText';
import styles from './Text.module.css';

const Text = (props) => {
  const { content, config, className } = props;
  return (
    <section className={mergeClassNames(className, styles.text)}>
      <RichText config={config} json={content && content.json} />
    </section>
  );
};

Text.propTypes = {
  content: shapes.richTextJson,
  config: shapes.config,
};

Text.defaultProps = {};

export default Text;
