import PropTypes from 'prop-types';
import React, { useState, Fragment } from 'react';
import { mergeClassNames } from '../utils';
import styles from './PageNavigationMobile.module.css';
import Hamburger from './Hamburger';

const PageNavigationMobile = (props) => {
  const { renderLogo, renderRoutes, renderContact } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <Fragment>
      <Hamburger
        className={mergeClassNames(styles.hamburger, {
          [styles.hamburgerOpen]: isOpen,
        })}
        isActive={isOpen}
        onClick={toggleOpen}
      />
      <nav
        className={mergeClassNames(styles.nav, {
          [styles.visible]: isOpen,
        })}
        onClick={toggleOpen}
      >
        {renderLogo(styles)}
        {renderRoutes(styles)}
        {renderContact(styles)}
      </nav>
    </Fragment>
  );
};

PageNavigationMobile.propTypes = {
  renderLogo: PropTypes.func.isRequired,
  renderRoutes: PropTypes.func.isRequired,
  renderContact: PropTypes.func.isRequired,
};

PageNavigationMobile.defaultProps = {};

export default PageNavigationMobile;
