import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { mergeClassNames } from '../utils';

import styles from './PreviewCompatibleImage.module.css';

const PreviewCompatibleImage = (props) => {
  const {
    className,
    style,
    image,
    minHeight,
    contain,
    backgroundFill,
    useSquared,
    alt,
    ...imageProps
  } = props;
  if (!image) return null;

  if (typeof image === 'string') {
    return (
      <img
        style={style}
        className={className}
        src={image}
        alt={alt}
        {...imageProps}
      />
    );
  }

  const { title } = image;

  // if (isIE) {
  //   return (
  //     <div
  //       style={{ ...style, backgroundImage: `url(${src})` }}
  //       className={mergeClassNames(
  //         className,
  //         styles.img,
  //         minHeight && styles.minHeight,
  //         contain && styles.contain,
  //         imageProps.onClick && styles.pointer
  //       )}
  //       alt={alt}
  //       {...imageProps}
  //     />
  //   );
  // }

  const fluid = (useSquared ? image.squared : image.fluid) || image;

  return (
    <span onClick={imageProps.onClick}>
      {backgroundFill && (
        <Img
          className={styles.backgroundFill}
          fluid={fluid}
          alt={alt || title}
          {...imageProps}
        />
      )}
      <Img
        style={style}
        className={mergeClassNames(
          { [styles.pointer]: imageProps.onClick },
          className
        )}
        fluid={fluid}
        alt={alt || title}
        {...imageProps}
      />
    </span>
  );
};

PreviewCompatibleImage.propTypes = {
  className: PropTypes.string,
  // isIE: PropTypes.bool,
  style: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

PreviewCompatibleImage.defaultProps = {
  className: '',
  // isIE: config.isIE,
};

export default PreviewCompatibleImage;
