import React from 'react';
import SVG from 'react-inlinesvg';
import email from '../assets/icons/email-outline.svg';
import phone from '../assets/icons/phone-outline.svg';
import globe from '../assets/icons/globe-outline.svg';
import printer from '../assets/icons/printer-outline.svg';
import loader from '../assets/icons/loader-outline.svg';
import arrow from '../assets/icons/arrow-forward-outline.svg';
import styles from './Icon.module.css';
import { mergeClassNames } from '../utils';

const icons = {
  email,
  phone,
  globe,
  printer,
  loader,
  arrow,
};

const Icon = ({ icon, className, tooltip, text, top, ...props }) => {
  return (
    <span className={mergeClassNames(styles.icon, className)} {...props}>
      <SVG src={icons[icon.toLowerCase()]} uniquifyIDs={false} />
      {text && <div className={mergeClassNames(styles.text)}>{text}</div>}
      {tooltip && (
        <div className={mergeClassNames(styles.tooltip, { [styles.top]: top })}>
          {tooltip}
        </div>
      )}
    </span>
  );
};

export default Icon;
