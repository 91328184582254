import { graphql } from 'gatsby';
import React from 'react';
import Page from '../../../src/components/Page';


const GatsbyAdapter = (props) => <Page {...props} />;

export default GatsbyAdapter;

export const query = graphql`
  fragment attachment on ContentfulAsset {
    title
    description
    file {
      url
      contentType
    }
    fluid(maxWidth: 1280, quality: 80) {
      ...GatsbyContentfulFluid_noBase64
    }
    squared: fluid(maxWidth: 450, maxHeight: 450, quality: 80) {
      ...GatsbyContentfulFluid_noBase64
    }
  }

  fragment resource on ContentfulResource {
    key
    value {
      json
    }
  }

  fragment paths on ContentfulPage {
    path
    title
    childPages {
      path
      title
    }
  }

  fragment pageContent on ContentfulPageContent {
    contentful_id
    type
    content {
      json
    }
    attachments {
      ...attachment
    }
    resources {
      ...resource
    }
    callToAction {
      label
      url
    }
  }

  query(
    $contentful_id: String
    $node_locale: String
  ) {
    page: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      path
      contentful_id
      metadata {
        title
        description
      }
      pageContent {
        __typename
        ... on ContentfulPageNavigation {
          contentful_id
          resources {
            ...resource
          }
          pages {
            ...paths
          }
        }
        ... on ContentfulPageEstates {
          contentful_id
          resources {
            ...resource
          }
          type
          references {
            contentful_id
            title
            image {
              ...attachment
            }
          }
        }
        ... on ContentfulPageContent {
          ...pageContent
        }
        ... on ContentfulPageGrid {
          contentful_id
          type
          content {
            json
          }
          backgroundImage {
            ...attachment
          }
          references {
            ...pageContent
          }
        }
        ... on ContentfulPageFooter {
          contentful_id
          resources {
            ...resource
          }
          pages {
            ...paths
          }
        }
      }
    }
  }
`;
