import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';
import { mergeClassNames } from '../utils';
import Card from './Card';
import ContactForm from './ContactForm';
import EstateDetail from './EstateDetail';
import Estates from './Estates';
import HeroImage from './HeroImage';
import Image from './Image';
import PageContent from './PageContent';
import styles from './PageContentRouter.module.css';
import PageFooter from './PageFooter';
import PageMap from './PageMap';
import PageNavigation from './PageNavigation';
import References from './References';
import RichText from './RichText';
import Text from './Text';

const ErrorComponent = ({ name, className }) => {
  return (
    <p className={mergeClassNames(className, styles.errorComponent)}>
      <code>"{name}"</code>
    </p>
  );
};

const PageContentTypeRouter = (props) => {
  const { typename, type, className = styles.component } = props;

  const mappings = {
    'Contact form': ContactForm,
    'Estate detail': EstateDetail,
    'Google Maps': PageMap,
    'Hero image': HeroImage,
    Card: Card,
    Image: Image,
    Text: Text,
  };

  const Component = mappings[type];
  if (Component) return <Component className={className} {...props} />;
  return (
    <ErrorComponent className={className} name={`${typename} > ${type}`} />
  );
};

const PageContentGrid = (props) => {
  const {
    backgroundImage,
    content,
    typename,
    references,
    type,
    config,
    pageContext,
  } = props;

  const mappings = {
    '1/2 1/2': styles.twoHalves,
    '1/3 1/3 1/3': styles.threeThirds,
    '1/3 2/3': styles.oneThirdTwoThird,
    '2/3 1/3': styles.twoThirdOneThird,
  };

  const heading = documentToPlainTextString(content?.json) && (
    <RichText json={content.json} />
  );
  const classNames = mergeClassNames(styles.grid, mappings[type]);

  const referenceNodes = (
    <div className={classNames}>
      {references.map((ref) => (
        <PageContentTypeRouter
          key={ref.contentful_id}
          {...ref}
          typename={`${typename} (${type})`}
          config={config}
          pageContext={pageContext}
        />
      ))}
    </div>
  );

  return backgroundImage?.fluid ? (
    <BackgroundImage
      className={styles.gridWrapper}
      Tag="div"
      fluid={backgroundImage?.fluid}
    >
      {heading}
      {referenceNodes}
    </BackgroundImage>
  ) : (
    <div className={styles.gridWrapper}>
      {heading}
      {referenceNodes}
    </div>
  );
};

const PageContentRouter = (props) => {
  const { typename, type } = props;

  const mappings = {
    ContentfulPageContent: PageContentTypeRouter,
    ContentfulPageEstates: type === 'References' ? References : Estates,
    ContentfulPageFooter: PageFooter,
    ContentfulPageGrid: PageContentGrid,
    ContentfulPageNavigation: PageNavigation,
  };

  const Component = mappings[typename];
  if (Component) return <Component {...props} />;
  return <ErrorComponent name={typename} />;
};

const commonPropTypes = { typename: PropTypes.string };
PageContentRouter.propTypes = PropTypes.oneOfType([
  PropTypes.shape({
    ...commonPropTypes,
    ...PageNavigation.propTypes,
  }),
  PropTypes.shape({
    ...commonPropTypes,
    ...PageContent.propTypes,
  }),
  PropTypes.shape({
    ...commonPropTypes,
    ...PageFooter.propTypes,
  }),
]).isRequired;

PageContentRouter.defaultProps = {
  typename: '<undefined>',
};

export default PageContentRouter;
