import PropTypes from 'prop-types';
import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import Layout from './Layout';
import PageContentRouter from './PageContentRouter';
import * as shapes from './proptypes';

const Page = (props) => {
  const {
    data: { page, estate },
    pageContext: { config },
    location: { state, search = '' },
  } = props;
  const { pageContent, metadata, contentful_id, ...restData } = page;

  const urlParams = new URLSearchParams(search);
  return (
    <Layout config={config} metadata={metadata} estateTitle={estate?.title}>
      {pageContent.map(({ __typename, ...content }, i) => {
        return (
          <PageContentRouter
            {...content}
            contentful_id={contentful_id}
            key={`${__typename}_${i}`}
            typename={__typename}
            pageContext={{ ...restData, estateId: state?.id, estateIdentifier: urlParams.get('id') }}
            config={config}
          />
        );
      })}
    </Layout>
  );
};

Page.propTypes = {
  pageContext: shapes.pageContext,
  data: PropTypes.shape({
    page: shapes.page.isRequired,
  }),
};

export default Page;
