import { navigate } from 'gatsby';
import React from 'react';
import Button from './Button';
import styles from './HeroImage.module.css';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import RichText from './RichText';
import { mergeClassNames } from '../utils';

const HeroImage = ({ attachments, content, callToAction }) => {
  return (
    <section className={mergeClassNames(styles.root)}>
      <PreviewCompatibleImage className={styles.image} image={attachments[0]} />
      <div
        className={mergeClassNames(styles.card, {
          [styles.background]: !!content,
        })}
      >
        {content && <RichText json={content?.json} />}
        {callToAction && (
          <Button onClick={() => navigate(callToAction.url)}>
            {callToAction.label}
          </Button>
        )}
      </div>
    </section>
  );
};

export default HeroImage;
