import PropTypes from 'prop-types';
import React from 'react';
import styles from './PageNavigationDesktop.module.css';

const PageNavigationDesktop = (props) => {
  const { renderLogo, renderRoutes, renderContact } = props;

  return (
    <nav className={styles.nav}>
      {renderLogo(styles)}
      {renderRoutes(styles)}
      {renderContact(styles)}
    </nav>
  );
};

PageNavigationDesktop.propTypes = {
  renderLogo: PropTypes.func.isRequired,
  renderRoutes: PropTypes.func.isRequired,
  renderContact: PropTypes.func.isRequired,
};

PageNavigationDesktop.defaultProps = {};

export default PageNavigationDesktop;
